$IS_SE_SITE: true; $IS_NO_SITE: false;
@import 'breakpoint-sass/stylesheets/breakpoint';
@import 'shared/styles/core/settings';
@import 'shared/styles/core/mixin';

$sizeSmall: 18px;

.label {
  display: flex;
  align-items: center;

  input[type='checkbox'] {
    // Hide and let <label> element render input ui
    position: absolute;
    opacity: 0;
  }
}

.disabled {
  opacity: 0.5;
}

.fill {
  .checkmarkWrapper {
    background-color: #fff;
  }
}

.checkmarkWrapper {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;

  :global(.icon-checkmark) {
    position: absolute;
    width: 20px;
    height: 20px;
    margin-top: -1px;
    margin-left: -1px;
  }

  &.small {
    width: $sizeSmall;
    height: $sizeSmall;

    :global(.icon-checkmark) {
      width: $sizeSmall;
      height: $sizeSmall;
    }
  }
}

.labelText {
  margin-left: 7px;
}

@include breakpoint($breakLarge) {
  .responsiveSize {
    .checkmarkWrapper {
      width: 26px;
      height: 26px;

      &.small {
        width: $sizeSmall;
        height: $sizeSmall;

        :global(.icon-checkmark) {
          width: $sizeSmall;
          height: $sizeSmall;
        }
      }
    }

    :global(.icon-checkmark) {
      width: 26px;
      height: 26px;
    }
  }
}
