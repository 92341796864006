td.money {
  text-align: right;
}

.no-touch-scroll {
  @include touch-only {
    position: fixed;
    overflow: hidden;
    -webkit-overflow-scrolling: auto;
  }
}

.overflow-vertical {
  @extend %overflow-vertical;
}

.animated-rotate {
  animation-name: rotate;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

// https://github.com/prettier/prettier/issues/9339
// prettier-ignore
@keyframes :global(rotate) {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.mq-small-show,
.mq-medium-show {
  display: none;
}

@include breakpoint($breakSmall) {
  .mq-small-show {
    display: block;
  }
}

@include breakpoint($breakMedium) {
  .mq-medium-show {
    display: block;
  }
}
