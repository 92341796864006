.fgrid {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.fgrid-cell {
  flex: 1;
}

.fgrid--flexCells > .fgrid-cell {
  display: flex;
}

.fgrid--top {
  align-items: flex-start;
}

.fgrid--bottom {
  align-items: flex-end;
}

.fgrid--center {
  align-items: center;
}

.fgrid--justifyCenter {
  justify-content: center;
}

.fgrid-cell--top {
  align-self: flex-start;
}

.fgrid-cell--bottom {
  align-self: flex-end;
}

.fgrid-cell--center {
  align-self: center;
}

.fgrid-cell--right {
  margin-left: auto;
}

.fgrid-cell--autoSize {
  flex: none;
}

.fgrid--fit > .fgrid-cell {
  flex: 1;
}

.fgrid--full > .fgrid-cell {
  flex: 0 0 100%;
}

.fgrid--1of2 > .fgrid-cell {
  flex: 0 0 50%;
}

.fgrid--1of3 > .fgrid-cell {
  flex: 0 0 33.3333%;
}

.fgrid--1of4 > .fgrid-cell {
  flex: 0 0 25%;
}

@include breakpoint(320px 479px) {
  .small-fgrid--full > .fgrid-cell {
    flex: 0 0 100%;
  }

  .small-fgrid--1of2 > .fgrid-cell {
    flex: 0 0 50%;
  }

  .small-fgrid--1of3 > .fgrid-cell {
    flex: 0 0 33.3333%;
  }

  .small-fgrid--1of4 > .fgrid-cell {
    flex: 0 0 25%;
  }
}

@include breakpoint($breakSmall) {
  .small-fgrid--fit > .fgrid-cell {
    flex: 1;
  }

  .small-fgrid--full > .fgrid-cell {
    flex: 0 0 100%;
  }

  .small-fgrid--1of2 > .fgrid-cell {
    flex: 0 0 50%;
  }

  .small-fgrid--1of3 > .fgrid-cell {
    flex: 0 0 33.3333%;
  }

  .small-fgrid--1of4 > .fgrid-cell {
    flex: 0 0 25%;
  }
}

@include breakpoint($breakMedium) {
  .med-fgrid--fit > .fgrid-cell {
    flex: 1;
  }

  .med-fgrid--full > .fgrid-cell {
    flex: 0 0 100%;
  }

  .med-fgrid--1of2 > .fgrid-cell {
    flex: 0 0 50%;
  }

  .med-fgrid--1of3 > .fgrid-cell {
    flex: 0 0 33.3333%;
  }

  .med-fgrid--1of4 > .fgrid-cell {
    flex: 0 0 25%;
  }
}

@include breakpoint($breakLarge) {
  .large-fgrid--fit > .fgrid-cell {
    flex: 1;
  }

  .large-fgrid--full > .fgrid-cell {
    flex: 0 0 100%;
  }

  .large-fgrid--1of2 > .fgrid-cell {
    flex: 0 0 50%;
  }

  .large-fgrid--1of3 > .fgrid-cell {
    flex: 0 0 33.3333%;
  }

  .large-fgrid--1of4 > .fgrid-cell {
    flex: 0 0 25%;
  }
}

.fgrid--gutters {
  margin: -1em 0 1em -1em;
}

.fgrid--gutters > .fgrid-cell {
  padding: 1em 0 0 1em;
}

.fgrid--guttersLg {
  margin: -1.5em 0 1.5em -1.5em;
}

.fgrid--guttersLg > .fgrid-cell {
  padding: 1.5em 0 0 1.5em;
}

.fgrid--guttersXl {
  margin: -2em 0 2em -2em;
}

.fgrid--guttersXl > .fgrid-cell {
  padding: 2em 0 0 2em;
}

@include breakpoint($breakSmall) {
  .small-fgrid--gutters {
    margin: -1em 0 1em -1em;
  }

  .small-fgrid--gutters > .fgrid-cell {
    padding: 1em 0 0 1em;
  }

  .small-fgrid--guttersLg {
    margin: -1.5em 0 1.5em -1.5em;
  }

  .small-fgrid--guttersLg > .fgrid-cell {
    padding: 1.5em 0 0 1.5em;
  }

  .small-fgrid--guttersXl {
    margin: -2em 0 2em -2em;
  }

  .small-fgrid--guttersXl > .fgrid-cell {
    padding: 2em 0 0 2em;
  }
}

@include breakpoint($breakMedium) {
  .med-fgrid--gutters {
    margin: -1em 0 1em -1em;
  }

  .med-fgrid--gutters > .fgrid-cell {
    padding: 1em 0 0 1em;
  }

  .med-fgrid--guttersLg {
    margin: -1.5em 0 1.5em -1.5em;
  }

  .med-fgrid--guttersLg > .fgrid-cell {
    padding: 1.5em 0 0 1.5em;
  }

  .med-fgrid--guttersXl {
    margin: -2em 0 2em -2em;
  }

  .med-fgrid--guttersXl > .fgrid-cell {
    padding: 2em 0 0 2em;
  }
}

@include breakpoint($breakLarge) {
  .large-fgrid--gutters {
    margin: -1em 0 1em -1em;
  }

  .large-fgrid--gutters > .fgrid-cell {
    padding: 1em 0 0 1em;
  }

  .large-fgrid--guttersLg {
    margin: -1.5em 0 1.5em -1.5em;
  }

  .large-fgrid--guttersLg > .fgrid-cell {
    padding: 1.5em 0 0 1.5em;
  }

  .large-fgrid--guttersXl {
    margin: -2em 0 2em -2em;
  }

  .large-fgrid--guttersXl > .fgrid-cell {
    padding: 2em 0 0 2em;
  }
}
