$IS_SE_SITE: true; $IS_NO_SITE: false;
@import 'shared/styles/core/settings';
@import 'shared/styles/core/mixin';
@import 'breakpoint-sass/stylesheets/breakpoint';

.menu {
  background-color: #000;

  :global(.icon-checkcircle),
  :global(.icon-user),
  :global(.icon-update) {
    margin-right: 4px;
  }
}

.menuInner {
  display: flex;
  position: relative;
  align-items: center;
  height: $menu-height;

  @include breakpoint($breakMedium) {
    height: $menu-height-medium;
  }

  @include breakpoint($breakLarge) {
    height: $menu-height-big;
  }
}

.logoWrapper {
  flex: 0 0 80px;
  padding-right: 26px;
  line-height: 1;

  a {
    display: block;
  }

  img {
    width: 100%;
    height: auto;
  }

  @include breakpoint($breakMedium) {
    flex-basis: 96px;
  }

  @include breakpoint($breakLarge) {
    flex-basis: 156px;
    padding-right: 50px;
  }
}

.logoWrapperNorway {
  flex: 0 0 94px;

  @include breakpoint($breakMedium) {
    flex-basis: 114px;
  }

  @include breakpoint($breakLarge) {
    flex-basis: 185px;
  }
}

.menuWrapper {
  flex: 1 1 auto;
}

.menuList {
  display: flex;
  width: 100%;
}

.popupMenuList {
  position: absolute;
  z-index: 300;
  top: $menu-height;
  left: 0;
  width: 100%;
  max-height: calc(100vh - $menu-height);
  overflow-y: auto;
  transform: scale(1, 0);
  transform-origin: center -5px;
  transition-property: transform, opacity;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
  opacity: 0;
  background-color: #000;
  box-shadow: 0 5px 7px 0 rgb(0 0 0 / 20%);

  &.active {
    transform: scale(1, 1);
    opacity: 1;
  }

  @include breakpoint($breakMedium) {
    top: $menu-height-medium;
    right: 0;
    left: auto;
    width: auto;
  }

  @include breakpoint($breakLarge) {
    top: $menu-height-big;
  }
}

.popupProfileData {
  margin: 0 0 7px;
  background-color: #333;
  color: #fff;
}

.profileData {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 18px 16px;
}

.profileDataText {
  flex-basis: 80%;
  margin-right: 12px;
}

.profileName {
  @extend %fontBold;

  padding: 0 0 5px;
  font-size: 16px;
  line-height: 18px;
  overflow-wrap: anywhere;
}

.subscription {
  margin-top: 4px;
  font-size: 12px;
  line-height: 18px;
}

.profileImg {
  position: relative;

  &.default {
    &::after {
      @extend %fontBold;

      content: '+';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 17px;
      height: 17px;
      padding: 1px 0 0 1px;
      overflow: hidden;
      transition-duration: 0.1s;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 0 4px rgba(#000, 0.3);
      color: #009aff;
      font-size: 14px;
      text-align: center;
      cursor: pointer;
    }
  }

  img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }

  &:hover {
    .icon,
    &.default::after {
      background-color: #009aff;
      fill: #fff;
      color: #fff;
    }
  }

  a {
    display: block;
    position: relative;

    .icon,
    &::after {
      @extend %fontBold;

      position: absolute;
      right: 0;
      bottom: 0;
      width: 18px;
      height: 18px;
      padding: 3px;
      overflow: hidden;
      transition-duration: 0.1s;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 0 4px rgba(#000, 0.3);
      fill: #009aff;
      font-size: 14px;
      text-align: center;
      cursor: pointer;
    }
  }
}

.menuProfileLive {
  padding: 18px 16px;
  border-top: 1px solid #1c1c1c;
  font-size: 14px;
}

.alias {
  padding: 5px 0 15px;

  a {
    color: #fff;
    text-decoration: underline;
  }
}

.play {
  display: flex;
  align-items: center;

  .toggle {
    margin: 0 10px 0 0;

    $uncheckedTrackColor: #9b9b9b;
    $checkedTrackColor: #0096ff;

    :global(.react-toggle-track) {
      width: 34px;
      height: 19px;
      border-radius: 12px;
      background-color: $uncheckedTrackColor;
    }

    :global(.react-toggle-thumb) {
      top: 2px;
      left: 2px;
      width: 15px;
      height: 15px;
      border: 0;
      background-color: #272727;
      box-shadow: 0 1px 1px 0 rgb(123 123 123 / 50%);
    }

    &:global(.react-toggle--disabled) {
      opacity: 0.3;
    }

    &:hover:not(:global(.react-toggle--disabled)) :global(.react-toggle-track) {
      background-color: darken($uncheckedTrackColor, 10);
    }

    &:global(.react-toggle--checked) {
      &:hover:not(:global(.react-toggle--disabled))
        :global(.react-toggle-track) {
        background-color: darken($checkedTrackColor, 10);
      }

      :global(.react-toggle-track) {
        background-color: $checkedTrackColor;
      }

      :global(.react-toggle-thumb) {
        left: 17px;
        background-color: #fff;
      }
    }
  }
}
