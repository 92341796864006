.loader {
  display: none;

  &--visible {
    display: block;
  }

  &--pending {
    visibility: hidden;
  }

  &--fullscreen,
  &--cover {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    h1 {
      height: 40px;
    }
  }

  &--fullscreen {
    position: fixed;
    z-index: 3000;
  }

  &--cover {
    position: absolute;
    z-index: 1;
  }

  &--with-dark-bg {
    background-color: rgb(0 0 0 / 90%);
  }

  &--with-light-bg {
    background-color: rgb(0 0 0 / 40%);
  }

  .loader__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  h1 {
    margin: 0;
    padding-top: 14px;
    color: #000;
    font-size: 20px;
    font-weight: normal;
  }

  .spinner {
    display: inline-block;
    width: 64px;
    height: 64px;
    background-image: url('../../../../images/main-loader-dark.png');
    background-position: top left;
    background-size: 64px 64px;
  }

  &--with-dark-bg,
  &--with-light-bg,
  &--light-spinner {
    h1 {
      color: #fff;
    }

    .spinner {
      background-image: url('../../../../images/main-loader-light.png');
    }
  }
}

.sprite.spinner,
.loader .spinner {
  animation-name: spinner-rotate;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

// https://github.com/prettier/prettier/issues/9339
// prettier-ignore
@keyframes :global(spinner-rotate) {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
