.component-loading-section {
  &--animated {
    transform: translateY(-8px);
    opacity: 0;
  }

  &--animated-visible {
    animation-name: componentLoadingSection;
    animation-duration: 0.2s;
    animation-timing-function: ease-in;
    animation-delay: 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

// https://github.com/prettier/prettier/issues/9339
// prettier-ignore
@keyframes :global(componentLoadingSection) {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
