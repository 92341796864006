$IS_SE_SITE: true; $IS_NO_SITE: false;
.burgerButton {
  position: relative;
  width: 24px;
  height: 100%;
}

.line {
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  transform: rotate(0deg);
  transition: 0.25s all ease-out;
  border-radius: 2px;
  opacity: 1;
  background: #fff;

  $innerHeight: 12px;

  &:nth-child(1) {
    top: calc(50% - #{$innerHeight * 0.5});
  }

  &:nth-child(2),
  &:nth-child(3) {
    top: calc(50% - #{$innerHeight * 0.5} + 5px);
  }

  &:nth-child(4) {
    top: calc(50% - #{$innerHeight * 0.5} + 10px);
  }

  .burgerButton.active & {
    &:nth-child(1),
    &:nth-child(4) {
      top: calc(50% - #{$innerHeight * 0.5} + 5px);
      left: 50%;
      width: 0%;
    }

    &:nth-child(2) {
      transform: rotate(45deg);
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
    }
  }
}
