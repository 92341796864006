.instructions {
  margin-top: 40px;

  & + .instructions {
    margin-top: 20px;
  }

  .player {
    position: relative;
    height: 0;
    margin-bottom: 8px;
    padding-bottom: 56.25%; // 16x9 ratio
  }

  li + li {
    margin-top: 24px;
  }

  iframe {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  @include breakpoint($breakMedium) {
    ul {
      display: flex;
      flex-flow: row wrap;
      margin: 0 -20px;
    }

    li {
      flex: 0 0 50%;
      padding: 0 20px;
    }

    li:nth-child(2) {
      margin-top: 0;
    }

    li + li {
      margin-top: 40px;
    }
  }

  @include breakpoint($breakLarge) {
    margin-top: 100px;

    & + .instructions {
      margin-top: 40px;
    }
  }
}
