@mixin sprite {
  display: inline-block;
  overflow: hidden;
}

@mixin spriteImage($image, $width, $height) {
  width: #{$width}px;
  height: #{$height}px;

  // The base path is relative to where the mixin is used
  background-image: url('../../../images/sprites/#{$image}.png');
  background-repeat: no-repeat;
  background-size: contain;
}

@mixin button-check {
  @include spriteImage('button-check', 15, 14);
}

@mixin button-cup {
  @include spriteImage('button-cup', 17, 17);
}

@mixin button-external {
  @include spriteImage('button-external', 7, 8);
}

@mixin button-play {
  @include spriteImage('button-play', 14, 14);
}

@mixin button-play-white {
  @include spriteImage('button-play-white', 14, 14);
}

@mixin button-first-big {
  @include spriteImage('button-first-big', 32, 29);
}

@mixin button-first {
  @include spriteImage('button-first', 22, 19);
}

@mixin button-last-big {
  @include spriteImage('button-last-big', 32, 29);
}

@mixin button-last {
  @include spriteImage('button-last', 21, 19);
}

@mixin button-edit-big {
  @include spriteImage('button-edit-big', 27, 27);
}

@mixin button-edit {
  @include spriteImage('button-edit', 18, 18);
}

@mixin button-edit-white-big {
  @include spriteImage('button-edit-white-big', 27, 27);
}

@mixin button-edit-white {
  @include spriteImage('button-edit-white', 18, 18);
}

@mixin button-minus-big {
  @include spriteImage('button-minus-big', 25, 4);
}

@mixin button-minus-small {
  @include spriteImage('button-minus-small', 9, 2);
}

@mixin button-minus {
  @include spriteImage('button-minus', 17, 3);
}

@mixin button-numpad-plus {
  @include spriteImage('button-numpad-plus', 21, 29);
}

@mixin button-numpad {
  @include spriteImage('button-numpad', 14, 19);
}

@mixin button-plus-big {
  @include spriteImage('button-plus-big', 25, 25);
}

@mixin button-plus-small {
  @include spriteImage('button-plus-small', 9, 9);
}

@mixin button-plus {
  @include spriteImage('button-plus', 17, 17);
}

@mixin button-trash-small {
  @include spriteImage('button-trash-small', 11, 13);
}

@mixin dialog-cancel {
  @include spriteImage('dialog-cancel', 50, 50);
}

@mixin dialog-confirm {
  @include spriteImage('dialog-confirm', 50, 50);
}

@mixin dropdown {
  @include spriteImage('dropdown', 25, 25);
}

@mixin item-add {
  @include spriteImage('item-add', 25, 25);
}

@mixin item-delete {
  @include spriteImage('item-delete', 25, 25);
}

@mixin item-group-expand {
  @include spriteImage('item-group-expand', 8, 7);
}

@mixin more-black-big {
  @include spriteImage('more-black-big', 14, 14);
}

@mixin more-black {
  @include spriteImage('more-black', 9, 9);
}

@mixin more-white-big {
  @include spriteImage('more-white-big', 14, 14);
}

@mixin more-white {
  @include spriteImage('more-white', 9, 9);
}

@mixin percent-sign {
  @include spriteImage('percent-sign', 25, 23);
}

@mixin scratched-big {
  @include spriteImage('scratched-big', 65, 65);
}

@mixin scratched {
  @include spriteImage('scratched', 43, 43);
}

@mixin shopping-cart {
  @include spriteImage('shopping-cart', 8, 8);
}

@mixin spinner {
  @include spriteImage('spinner', 16, 16);
}

@mixin view-result-hidden {
  @include spriteImage('view-result-hidden', 16, 15);
}

@mixin view-result {
  @include spriteImage('view-result', 16, 15);
}

@mixin warning {
  @include spriteImage('warning', 16, 16);
}
