.bet-body {
  $topMin: $menu-height + $top-info-height;
  $topMid: $menu-height-medium + $top-info-height;
  $topMax: $menu-height-big + $top-info-height-big;

  @extend %overflow-both;

  position: absolute;
  inset: $topMin 0 0 0;

  > .middle {
    position: relative;
  }

  // Since collaboration is a fixed overlay in bottom-right corner, a slight
  // padding is needed to ensure the whole bet body can be viewed properly.
  // --padding-bottom-view applies to views which don't have conditions at the bottom.
  // Views with conditions will have padding-bottom set inside the conditions instead.
  &--with-collaboration {
    &.bet-body--padding-bottom-view {
      padding-bottom: 50px;

      @include breakpoint($breakSmall) {
        padding-bottom: 80px;
      }
    }
  }

  &--sport {
    display: flex;
    flex-direction: column;
  }

  @include breakpoint($breakMedium) {
    top: $topMid;
  }

  @include breakpoint($breakLarge) {
    top: $topMax;
  }
}
