.bet-header {
  position: fixed;
  z-index: 101;
  top: $menu-height;
  left: 0;
  width: 100%;
  height: $top-info-height;
  color: #fff;
  font-size: 11px;

  .icon-error-circle {
    fill: #e80000;
  }

  .icon-cached {
    fill: currentColor;
  }

  &--full-width {
    display: flex;
    align-items: center;
  }

  &__content-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
  }

  @include breakpoint($breakMedium) {
    top: $menu-height-medium;
  }

  @include breakpoint($breakLarge) {
    top: $menu-height-big;
    height: $top-info-height-big;
  }
}
