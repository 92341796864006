$IS_SE_SITE: true; $IS_NO_SITE: false;
.accountItem {
  composes: flex flex--row-center from global;
}

.titleUsername {
  max-width: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  text-transform: uppercase;
}
