$IS_SE_SITE: true; $IS_NO_SITE: false;
@import 'shared/styles/core/settings';
@import 'shared/styles/core/mixin';
@import 'breakpoint-sass/stylesheets/breakpoint';

$cMenuBlue: #1b89c6;

@mixin menuButtonColor($color, $activeColor, $activeBgColor: #fff) {
  color: $color;

  :global(.svg-icon) {
    transition: fill 0.25s ease;
    fill: $color;
  }

  &:not(:global(.disabled)) {
    &:global(.active) {
      &::after {
        background-color: $activeBgColor;
      }
    }

    &:active {
      color: $activeColor;

      :global(.svg-icon) {
        fill: $activeColor;
      }

      &::after {
        background-color: transparent;
      }

      &:global(.active) {
        &::after {
          background-color: $activeColor;
        }
      }
    }

    @include hover {
      color: $activeColor;

      :global(.svg-icon) {
        fill: $activeColor;
      }

      &::after {
        background-color: transparent;
      }

      &:global(.active) {
        &::after {
          background-color: $activeColor;
        }
      }
    }
  }
}

.menuItem {
  &:global(.list-menu-item) {
    display: inline-block;
    position: relative;
    flex: 0 0 auto;

    + .menuItem {
      padding-left: 14px;

      @include breakpoint($breakLarge) {
        padding-left: 16px;

        &::after {
          top: 18%;
          bottom: 18%;
        }
      }

      @include breakpoint($breakXLarge) {
        padding-left: 22px;
      }
    }

    &:global(.menu-item--burger) {
      margin-left: auto;

      @include breakpoint($breakMedium) {
        display: none;
      }
    }
  }

  &:global(.popup-menu-item) {
    &:global(.menu-item--logout) {
      @include breakpoint($breakMedium) {
        margin: 10px 0 0;
        border-top: 1px solid #8c8c8c;
      }
    }
  }
}

.menuButton {
  display: inline-block;
  position: relative;
  transition-property: color, opacity;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  color: #fff;
  font-weight: $font-weight-semi-bold;
  text-decoration: none;
  white-space: nowrap;

  .newTag {
    @extend %fontBold;

    position: absolute;
    top: 6px;
    right: -8px;
    padding: 2px 3px;
    background-color: #d72222;
    color: #fff;
    font-size: 10px;
    line-height: 1.1;
  }

  &::after {
    content: '';
    position: absolute;
    transition: 0.25s background-color ease;
    background-color: transparent;
  }

  @include menuButtonColor(#fff, $cMenuBlue);

  &:global(.disabled) {
    opacity: $disabled-opacity;
    cursor: default;
  }

  :global(.menu-item--atg_export) &,
  :global(.menu-item--atg_manual) &,
  :global(.menu-item--sport_export) & {
    @include menuButtonColor(#8ab53e, #6e9132, #6e9132);
  }

  :global(.list-menu-item) & {
    font-size: 10px;

    &::after {
      bottom: 0;
      left: -2px;
      width: calc(100% + 4px);
      height: 3px;
    }

    .title {
      text-transform: uppercase;
    }

    .newTag {
      top: 2px;
      right: -10px;
      padding: 1px 3px;
      font-size: 9px;

      &::after {
        content: '';
        position: absolute;
        top: 11px;
        right: 0;
        width: 0;
        height: 0;
        transform: rotate(-45deg);
        border-right: 6px solid transparent;
        border-bottom: 6px solid #8c1f1b;
        border-left: 6px solid transparent;
      }
    }

    @include breakpoint($breakMedium) {
      padding: 0;

      .newTag {
        top: 4px;
        padding: 2px 3px;

        &::after {
          top: 13px;
        }
      }
    }

    @include breakpoint($breakLarge) {
      font-size: 14px;

      .newTag {
        top: 8px;
        right: -8px;
        font-size: 10px;

        &::after {
          top: 14px;
        }
      }
    }
  }

  :global(.popup-menu-item) & {
    width: 100%;
    padding-left: 16px;
    font-size: 14px;

    &::after {
      top: 0;
      left: 0;
      width: 3px;
      height: 100%;
    }

    .newTag {
      position: relative;
      top: -6px;
      right: -4px;
      padding: 2px 5px;
      border-radius: 8px;
    }

    @include breakpoint($breakMedium) {
      padding: 0 16px;
    }
  }
}

a.menuButton {
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

.menuButtonInner {
  composes: flex flex--row-center from global;

  :global(.list-menu-item) & {
    height: $menu-height;

    @include breakpoint($breakMedium) {
      height: $menu-height-medium;
    }

    @include breakpoint($breakLarge) {
      height: $menu-height-big;
    }
  }

  :global(.popup-menu-item) & {
    height: 30px;
  }

  :global(.menu-item--logout.popup-menu-item) & {
    @include breakpoint($breakMedium) {
      height: 46px;
    }
  }
}

/**
 *  Category overrides: CONTENT 
 */
.menuItem {
  &:global(.list-menu-item) {
    :global(.menu--category-content) & {
      &:global(.menu-item--trmedia_account),
      &:global(.menu-item--atg_settings),
      &:global(.menu-item--subscription),
      &:global(.menu-item--logout) {
        display: none;
      }

      @include breakpoint($breakSmallDown) {
        &:global(.menu-item--about),
        &:global(.menu-item--instructions),
        &:global(.menu-item--contact),
        &:global(.menu-item--account),
        &:global(.menu-item--news),
        &:global(.menu-item--trmedia_account),
        &:global(.menu-item--faq),
        &:global(.menu-item--register),
        &:global(.menu-item--logout) {
          display: none;
        }

        &:global(.menu-item--burger) {
          margin-left: auto;
        }
      }

      @include breakpoint((min-width $breakSmall) ($breakMobileDown)) {
        &:global(.menu-item--logout),
        &:global(.menu-item--instructions),
        &:global(.menu-item--account),
        &:global(.menu-item--contact) {
          display: none;
        }
      }

      @include breakpoint($breakMedium) {
        &:global(.menu-item--register),
        &:global(.menu-item--account) {
          margin-left: auto;
        }
      }
    }

    :global(.menu--category-content .no-auth) & {
      @include breakpoint($breakSmallDown) {
        &:global(.menu-item--about),
        &:global(.menu-item--tips),
        &:global(.menu-item--monitor_horse) {
          display: none;
        }
      }

      @include breakpoint((min-width $breakSmall) ($breakMobileDown)) {
        &:global(.menu-item--instructions),
        &:global(.menu-item--about),
        &:global(.menu-item--faq),
        &:global(.menu-item--news) {
          display: none;
        }
      }
    }
  }

  &:global(.popup-menu-item) {
    :global(.menu--category-content) & {
      &:global(.menu-item--account) {
        display: none;
      }

      @include breakpoint($breakMedium) {
        &:global(.menu-item--about),
        &:global(.menu-item--contact),
        &:global(.menu-item--live),
        &:global(.menu-item--news),
        &:global(.menu-item--instructions),
        &:global(.menu-item--monitor_horse),
        &:global(.menu-item--faq) {
          display: none;
        }
      }

      @include breakpoint($breakSmallDown) {
        &:global(.menu-item--calendar),
        &:global(.menu-item--account),
        &:global(.menu-item--login) {
          display: none;
        }
      }

      @include breakpoint((min-width $breakSmall) ($breakMobileDown)) {
        &:global(.menu-item--login),
        &:global(.menu-item--register) {
          display: none;
        }
      }

      &:global(.popup-menu-item .has-auth) {
        @include breakpoint($breakSmallDown) {
          &:global(.menu-item--about) {
            display: none;
          }
        }

        @include breakpoint((min-width $breakSmall) ($breakMobileDown)) {
          &:global(.menu-item--instructions) {
            display: none;
          }
        }
      }
    }
  }
}

/**
 *  Category overrides: SPORT 
 */
.menuItem {
  &:global(.list-menu-item) {
    :global(.menu--category-sport) & {
      &:global(.menu-item--trmedia_account),
      &:global(.menu-item--atg_settings),
      &:global(.menu-item--news),
      &:global(.menu-item--subscription),
      &:global(.menu-item--logout) {
        display: none;
      }

      @include breakpoint($breakSmallDown) {
        &:global(.menu-item--sport_archive),
        &:global(.menu-item--instructions),
        &:global(.menu-item--register),
        &:global(.menu-item--account),
        &:global(.menu-item--news),
        &:global(.menu-item--logout) {
          display: none;
        }

        &:global(.menu-item--burger) {
          margin-left: auto;
        }
      }

      @include breakpoint((min-width $breakSmall) ($breakMobileDown)) {
        &:global(.menu-item--account),
        &:global(.menu-item--instructions),
        &:global(.menu-item--logout) {
          display: none;
        }
      }

      @include breakpoint($breakMedium) {
        &:global(.menu-item--sport_export) {
          margin-left: auto;
        }
      }
    }

    :global(.menu--category-sport .no-auth) & {
      @include breakpoint($breakSmallDown) {
        &:global(.menu-item--sport_export) {
          display: none;
        }
      }

      @include breakpoint((min-width $breakSmall) ($breakMobileDown)) {
        &:global(.menu-item--sport_archive) {
          display: none;
        }
      }
    }
  }

  &:global(.popup-menu-item) {
    :global(.menu--category-sport) & {
      &:global(.menu-item--account) {
        display: none;
      }

      @include breakpoint($breakSmallDown) {
        &:global(.menu-item--news),
        &:global(.menu-item--login) {
          display: none;
        }
      }

      @include breakpoint((min-width $breakSmall) ($breakMobileDown)) {
        &:global(.menu-item--sport_export),
        &:global(.menu-item--register),
        &:global(.menu-item--login) {
          display: none;
        }
      }

      @include breakpoint($breakMedium) {
        &:global(.menu-item--sport_archive),
        &:global(.menu-item--instructions),
        &:global(.menu-item--news),
        &:global(.menu-item--sport_export),
        &:global(.menu-item--register) {
          display: none;
        }
      }
    }

    :global(.menu--category-sport .has-auth) & {
      @include breakpoint($breakSmallDown) {
        &:global(.menu-item--sport_export) {
          display: none;
        }
      }

      @include breakpoint((min-width $breakSmall) ($breakMobileDown)) {
        &:global(.menu-item--sport_archive) {
          display: none;
        }
      }
    }
  }
}

/**
 *  Category overrides: ATG 
 */
.menuItem {
  &:global(.list-menu-item) {
    :global(.menu--category-atg) & {
      &:global(.menu-item--trmedia_account),
      &:global(.menu-item--news),
      &:global(.menu-item--logout) {
        display: none;
      }

      @include breakpoint($breakSmallDown) {
        &:global(.menu-item--atg_archive),
        &:global(.menu-item--atg_report),
        &:global(.menu-item--atg_coupons),
        &:global(.menu-item--tips),
        &:global(.menu-item--atg_settings),
        &:global(.menu-item--subscription),
        &:global(.menu-item--instructions),
        &:global(.menu-item--register),
        &:global(.menu-item--trmedia_account),
        &:global(.menu-item--account),
        &:global(.menu-item--logout) {
          display: none;
        }

        &:global(.menu-item--logout) {
          margin-left: auto;
        }
      }

      @include breakpoint((min-width $breakSmall) ($breakMobileDown)) {
        &:global(.menu-item--atg_coupons),
        &:global(.menu-item--atg_settings),
        &:global(.menu-item--subscription),
        &:global(.menu-item--instructions),
        &:global(.menu-item--register),
        &:global(.menu-item--trmedia_account),
        &:global(.menu-item--account),
        &:global(.menu-item--logout) {
          display: none;
        }
      }

      @include breakpoint($breakMedium) {
        &:global(.menu-item--atg_export),
        &:global(.menu-item--atg_manual) {
          margin-left: auto;
        }

        &:global(.menu-item--atg_settings),
        &:global(.menu-item--subscription) {
          display: none;
        }
      }
    }

    :global(.menu--category-atg .no-auth) & {
      @include breakpoint($breakSmallDown) {
        &:global(.menu-item--atg_export) {
          display: none;
        }
      }

      @include breakpoint((min-width $breakSmall) ($breakMobileDown)) {
        &:global(.menu-item--atg_manul) {
          display: none;
        }
      }
    }
  }

  &:global(.popup-menu-item) {
    :global(.menu--category-atg) & {
      &:global(.menu-item--account) {
        display: none;
      }

      @include breakpoint($breakSmallDown) {
        &:global(.menu-item--news),
        &:global(.menu-item--login) {
          display: none;
        }
      }

      @include breakpoint((min-width $breakSmall) ($breakMobileDown)) {
        &:global(.menu-item--atg_archive),
        &:global(.menu-item--atg_report),
        &:global(.menu-item--account),
        &:global(.menu-item--login) {
          display: none;
        }
      }

      @include breakpoint($breakMedium) {
        &:global(.menu-item--atg_export),
        &:global(.menu-item--atg_archive),
        &:global(.menu-item--atg_coupons),
        &:global(.menu-item--news),
        &:global(.menu-item--instructions),
        &:global(.menu-item--atg_manual),
        &:global(.menu-item--atg_report) {
          display: none;
        }
      }
    }

    :global(.menu--category-atg .has-auth) & {
      @include breakpoint($breakSmallDown) {
        &:global(.menu-item--atg_export),
        &:global(.menu-item--atg_manual) {
          display: none;
        }
      }

      @include breakpoint((min-width $breakSmall) ($breakMobileDown)) {
        &:global(.menu-item--atg_export),
        &:global(.menu-item--atg_manual) {
          display: none;
        }
      }
    }
  }
}
